import React, { useReducer } from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

import AppTheme from "./AppTheme"

import { initialState } from "./initialState"
import { AppReducer } from "./AppReducer"

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <ThemeToggler>{({ theme }) => <AppTheme theme={theme} />}</ThemeToggler>
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
