import "@fontsource/roboto-mono"
import "@fontsource/zen-kaku-gothic-antique"

import React from "react";
import { AppProvider } from "./src/context/AppContext";
import "./src/styles/global.scss";

export const wrapRootElement = ({ element }) => {
    return (
        <AppProvider>
            {element}
        </AppProvider>
    )
};