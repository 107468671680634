import { useContext, useEffect } from "react"
import { isBrowser } from "../services/general"
import { AppContext } from "./AppContext"

const AppTheme = ({ theme }) => {
  const { state, dispatch } = useContext(AppContext)

  useEffect(() => {
    let finalTheme =
      isBrowser() && localStorage.getItem("theme")
        ? localStorage.getItem("theme")
        : theme
    if (!state.isDark && finalTheme === "dark") {
      dispatch({ type: "SET_DARK" })
    } else if (!!state.isDark && finalTheme !== "dark") {
      dispatch({ type: "SET_DEFAULT" })
    }
  }, [])

  return null
}

export default AppTheme
