import { initialState } from "./initialState"

const RESET_DETAILS = "RESET_DETAILS"

const isDark = {
  SET_DARK: "SET_DARK",
  SET_DEFAULT: "SET_DEFAULT"
}

export let AppReducer = (state, action) => {
  switch (action.type) {
    case isDark.SET_DARK: 
      return {
        ...state,
        isDark: true,
      }

    case isDark.SET_DEFAULT: 
      return {
        ...state,
        isDark: false,
      }
    
    case RESET_DETAILS:
      return { ...initialState }

    default:
      return { ...initialState }
  }
}
